import React from "react";
import { useTranslation } from "react-i18next";
import CompareItem from "./CompareItem";

export default function Compare() {
 const { t } = useTranslation();
 return (
  <div className="container">
   <div className="twoGrid compareGrid">
    <CompareItem
     HeaderItem={
      <div className="flex sb flinnCompareHeader">
       <div className="flinnText">
        <div>FLINN</div>
       </div>
       <img
        className="law-person2"
        alt="law-person"
        src={require("../assets/lawPerson.png")}
       />
      </div>
     }
     fee={t("feeValue")}
     speed={t("min")}
     lang={t("yes")}
     res={t("yes")}
     issue={t("yes")}
    />

    <CompareItem
     HeaderItem={
      <div className="flex al-ce otherLawyersHeader">{t("otherLawyer")}</div>
     }
     className={"hide-mobile"}
     fee={t("otherLawyerFee")}
     speed={t("otherLawyerSpeed")}
     lang={t("no")}
     res={t("yes")}
     issue={t("extraCharge")}
    />
   </div>
  </div>
 );
}
