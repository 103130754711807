import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./App.css";
import Clarify from "./components/Clarify";
import Compare from "./components/Compare";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Section1 from "./components/Section1";
import WorldWide from "./components/WorldWide";
import Service from "./components/Service";

function App() {
    const [currentLanguage, setCurrentLanguage] = useState("en");
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem("language");
        if (language) {
            i18n.changeLanguage(language);
            setCurrentLanguage(language);
        }
    }, []);

    const setLanguage = (language) => {
        localStorage.setItem("language", language);
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
    };
    return (
        <div className={`App`}>
            <Header currentLanguage={currentLanguage} setCurrentLanguage={setLanguage} />
            <Hero />
            <div className="hide-pc">
                <Clarify />
            </div>
            <Section1 currentLanguage={currentLanguage} />
            <WorldWide currentLanguage={currentLanguage} />
            <div className="hide-mobile">
                <Clarify />
            </div>
            <Service />
            <div className="cheaper">{t("cheaper")}</div>
            <Compare />
            <Footer />
        </div>
    );
}

export default App;
