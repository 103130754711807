import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Arrow, Verified } from "../assets/SVG";
import Accordion from "./Accordion";
import { premiumNetworkData } from "../utils/content";
import { motion, useInView } from "framer-motion";

export default function WorldWide({ currentLanguage }) {
 const { t } = useTranslation();
 const [active, setActive] = useState(null);
 const ref = React.useRef(null);
 const isInView = useInView(ref);

 const getKeyframes = () => {
  if (window.innerWidth < 768) {
   return [60, -300, -700, -700, -280, 60];
  } else {
   return [0, -350, -700, -700, -350, 0];
  }
 };

 const getInitial = () => {
  if (window.innerWidth < 768) {
   return [60];
  } else {
   return [0];
  }
 };

 return (
  <div className="twoGrid hero worldWide">
   <div className="leftSection">
    <div className="heroText">{t("network")}</div>
    <div className="accordion">
     {premiumNetworkData.map(({ title, content }, i) => {
      return (
       <Accordion
        title={title}
        isActive={active}
        setIsActive={setActive}
        content={content}
        i={i}
       />
      );
     })}
    </div>
    <div
     onClick={() => {
      window.open("https://flinn-dashboard-fe.pages.dev/", "_blank");
     }}
     className="btn startAnalysis"
    >
     {t("startAnalysis")}
     <Arrow />
    </div>

    <div className="flex al-ce moneyBack hide-mobile">
     <Verified />
     <div>{t("moneyBack")}</div>
    </div>
   </div>
   <div ref={ref} className="rightSection">
    <div className="rightSectionContent">
     <motion.img
      animate={{
       opacity: 1,
       y: isInView ? getKeyframes() : getInitial(),
      }}
      transition={{
       duration: 10,
       times: [0, 0.2, 0.4, 0.6, 0.8, 1],
       repeat: Infinity,
       repeatDelay: 1,
       ease: "easeInOut",
       delay: 0.5,
      }}
      className="analysis-img"
      alt="law-Person"
      src={require("../assets/law.png")}
     />
    </div>
   </div>
  </div>
 );
}
