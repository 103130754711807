import React from "react";
import { useTranslation } from "react-i18next";
import { Arrow } from "../assets/SVG";

export default function StartBtn({ large, ...props }) {
 const { t } = useTranslation();

 return (
  <div
   onClick={() => {
    window.open("https://flinn-dashboard-fe.pages.dev/", "_blank");
   }}
   className={`btn ${large ? "btnLarge" : ""}`}
  >
   {t("startBtn")}
   <Arrow />
  </div>
 );
}
