import React from "react";
import { useTranslation } from "react-i18next";
import StartBtn from "./StartBtn";

export default function Clarify() {
 const { t } = useTranslation();
 return (
  <div className="container">
   <div className="twoGrid clarify">
    <div className="col flex jc-ce al-fs clarifyTextSection">
     <div className="clarifyText">{t("clarify")}</div>
     <StartBtn />
    </div>
    <div className="chanceSection">
     <div className="chanceBtn">{t("highChance")}</div>
    </div>
   </div>
  </div>
 );
}
