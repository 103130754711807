import React from "react";
import { useTranslation } from "react-i18next";
import { Close, Ham } from "../assets/SVG";
import { Language } from "./Header";

export default function MobileNav({
 currentLanguage,
 setCurrentLanguage,
 changeLanguageHandler,
}) {
 const [open, setOpen] = React.useState(false);
 const { t } = useTranslation();
 return (
  <div className="hide-pc">
   <div onClick={() => setOpen(!open)} className="ham">
    {open ? <Close /> : <Ham />}
   </div>

   <div className={`content ${open ? "open" : "close"}`}>
    <Language
     currentLanguage={currentLanguage}
     setCurrentLanguage={setCurrentLanguage}
     changeLanguageHandler={changeLanguageHandler}
    />
    <div
     onClick={() => {
      window.open(
       "https://help.hiflinn.com/en/articles/7257730-how-does-it-work",
       "_blank"
      );
     }}
     className="link"
    >
     {t("howItWorks")}
    </div>
    <div
     onClick={() => {
      window.open(
       "https://help.hiflinn.com/en/articles/7257731-pricing",
       "_blank"
      );
     }}
     className="link"
    >
     {t("pricing")}
    </div>
   </div>
  </div>
 );
}
