import React from "react";
import { useTranslation } from "react-i18next";

export default function Section1({ currentLanguage }) {
 const { t } = useTranslation();
 return (
  <div className="col flex section1 hide-mobile">
   <div className="legalTxt ">{t("legal")}</div>

   <img
    className="caseFlow"
    src={require(currentLanguage === "de"
     ? "../assets/caseFlowDe.png"
     : "../assets/caseFlow.png")}
   />
  </div>
 );
}
