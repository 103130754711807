export const Logo = () => (
 <svg
  width="52"
  height="13"
  viewBox="0 0 52 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M3.248 13H0.386V0.237999H8.612V2.938H3.23V5.638H7.964V8.23H3.248V13ZM18.9685 13H10.6165V0.237999H13.4605V10.282H18.9685V13ZM23.6918 13H20.8118V0.237999H23.6918V13ZM37.5424 13H34.5364L29.2804 4.612V13H26.4544V0.237999H29.9104L34.6984 7.996V0.237999H37.5424V13ZM51.3763 13H48.3703L43.1143 4.612V13H40.2883V0.237999H43.7443L48.5323 7.996V0.237999H51.3763V13Z"
   fill="black"
  />
 </svg>
);

export const Arrow = () => (
 <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M5.25 12.75L12.75 5.25M12.75 5.25H5.25M12.75 5.25V12.75"
   stroke="white"
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const ArrowDown = () => (
 <svg
  width="20"
  height="22"
  viewBox="0 0 20 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M5 8.25L10 13.75L15 8.25"
   stroke="black"
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const Verified = () => (
 <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M6.75 9L8.25 10.5L11.625 7.125M13.4259 3.74888C13.5803 4.1224 13.8768 4.4193 14.25 4.5743L15.5589 5.11648C15.9325 5.27121 16.2292 5.568 16.384 5.94154C16.5387 6.31509 16.5387 6.73481 16.384 7.10836L15.8422 8.41635C15.6874 8.79007 15.6872 9.21021 15.8427 9.58374L16.3835 10.8913C16.4602 11.0764 16.4997 11.2747 16.4997 11.475C16.4998 11.6752 16.4603 11.8736 16.3837 12.0586C16.3071 12.2436 16.1947 12.4118 16.0531 12.5534C15.9114 12.695 15.7433 12.8073 15.5582 12.8839L14.2503 13.4256C13.8768 13.5801 13.5799 13.8765 13.4249 14.2498L12.8827 15.5588C12.728 15.9323 12.4312 16.2291 12.0577 16.3838C11.6841 16.5386 11.2644 16.5386 10.8909 16.3838L9.58296 15.842C9.20942 15.6877 8.78987 15.688 8.41656 15.8429L7.10767 16.3843C6.73434 16.5387 6.31501 16.5386 5.94178 16.384C5.56855 16.2293 5.27194 15.9329 5.11711 15.5598L4.57479 14.2504C4.42035 13.8769 4.12391 13.58 3.75064 13.425L2.44175 12.8828C2.06838 12.7282 1.77169 12.4316 1.61691 12.0582C1.46213 11.6849 1.46192 11.2654 1.61633 10.8919L2.1581 9.58391C2.31244 9.21035 2.31213 8.79079 2.15722 8.41746L1.61623 7.10759C1.53953 6.92257 1.50003 6.72426 1.5 6.52397C1.49997 6.32369 1.5394 6.12536 1.61604 5.94032C1.69268 5.75529 1.80504 5.58716 1.94668 5.44556C2.08832 5.30396 2.25647 5.19166 2.44152 5.11508L3.74947 4.57329C4.12265 4.41898 4.41936 4.1229 4.57448 3.75004L5.11664 2.44111C5.27136 2.06756 5.56813 1.77078 5.94167 1.61605C6.3152 1.46132 6.7349 1.46132 7.10844 1.61605L8.41638 2.15784C8.78993 2.31218 9.20948 2.31187 9.58279 2.15696L10.8922 1.61689C11.2657 1.46224 11.6853 1.46228 12.0588 1.61697C12.4322 1.77167 12.729 2.06837 12.8837 2.44182L13.426 3.75115L13.4259 3.74888Z"
   stroke="black"
   strokeWidth="1.5"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const PC = () => (
 <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M11.25 12.75V15.75H6.75V12.75M3.9 12.75H14.1C14.9401 12.75 15.3601 12.75 15.681 12.5865C15.9632 12.4427 16.1927 12.2132 16.3365 11.931C16.5 11.6101 16.5 11.1901 16.5 10.35V4.65C16.5 3.80992 16.5 3.38988 16.3365 3.06901C16.1927 2.78677 15.9632 2.5573 15.681 2.41349C15.3601 2.25 14.9401 2.25 14.1 2.25H3.9C3.05992 2.25 2.63988 2.25 2.31901 2.41349C2.03677 2.5573 1.8073 2.78677 1.66349 3.06901C1.5 3.38988 1.5 3.80992 1.5 4.65V10.35C1.5 11.1901 1.5 11.6101 1.66349 11.931C1.8073 12.2132 2.03677 12.4427 2.31901 12.5865C2.63988 12.75 3.05992 12.75 3.9 12.75Z"
   stroke="#32C850"
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const Ham = () => (
 <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M3 12H21M3 6H21M3 18H21"
   stroke="black"
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>
);

export const Close = () => (
 <svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M2.31188 13.6827C2.7398 14.1033 3.46509 14.0961 3.85675 13.6972L7.99818 9.55575L12.1396 13.6972C12.5458 14.1033 13.2638 14.1033 13.6772 13.6827C14.0979 13.2547 14.1051 12.5512 13.6917 12.1378L9.55756 7.99637L13.6917 3.86221C14.1051 3.44879 14.1051 2.73801 13.6772 2.31734C13.2566 1.89667 12.5458 1.89667 12.1396 2.30283L7.99818 6.44425L3.85675 2.30283C3.46509 1.90392 2.73255 1.88942 2.31188 2.31734C1.89121 2.73801 1.90571 3.4633 2.29737 3.86221L6.44605 7.99637L2.29737 12.145C1.90571 12.5367 1.89121 13.2692 2.31188 13.6827Z"
   fill="#16161A"
  />
 </svg>
);
