import React from "react";
import { Logo } from "../assets/SVG";
import StartBtn from "./StartBtn";
import { useTranslation } from "react-i18next";
import MobileNav from "./MobileNav";

export default function Header({ currentLanguage, setCurrentLanguage }) {
 const { t, i18n } = useTranslation();

 const changeLanguageHandler = (e) => {
  const languageValue = e;
  i18n.changeLanguage(languageValue);
 };

 return (
  <div className="container">
   <div className="header flex al-ce sb">
    <div className="brand">
     <Logo />
    </div>

    <div className="flex al-ce hide-mobile">
     <div
      onClick={() => {
       window.open(
        "https://help.hiflinn.com/en/articles/7257731-pricing",
        "_blank"
       );
      }}
      className="link"
     >
      {t("pricing")}
     </div>
     <div
      onClick={() => {
       window.open(
        "https://help.hiflinn.com/en/articles/7257730-how-does-it-work",
        "_blank"
       );
      }}
      className="link how"
     >
      {t("howItWorks")}
     </div>
     <Language
      currentLanguage={currentLanguage}
      setCurrentLanguage={setCurrentLanguage}
      changeLanguageHandler={changeLanguageHandler}
     />
     <StartBtn />
    </div>
    <MobileNav
     currentLanguage={currentLanguage}
     setCurrentLanguage={setCurrentLanguage}
     changeLanguageHandler={changeLanguageHandler}
    />
   </div>
  </div>
 );
}

export const Language = ({
 currentLanguage,
 setCurrentLanguage,
 changeLanguageHandler,
}) => {
 return (
  <div className="flex al-ce language">
   <div
    className={`${currentLanguage !== "de" ? "light" : "dark"} pointer`}
    onClick={() => {
     changeLanguageHandler("de");
     setCurrentLanguage("de");
    }}
   >
    DE
   </div>
   <div className={`${currentLanguage !== "de" ? "light" : "dark"} break`}>
    |
   </div>
   <div
    className={`${currentLanguage !== "en" ? "light" : "dark"} pointer`}
    onClick={() => {
     changeLanguageHandler("en");
     setCurrentLanguage("en");
    }}
   >
    EN
   </div>
  </div>
 );
};
