import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
 const { t } = useTranslation();
 return (
  <div className="footerBg">
   <div className="container">
    <footer>
     <div className="footerGrid">
      <div>
       <div className="footerHeader">FLINN</div>
       <div className="footerDetails">{t("modernLawyer")}</div>
       <div className="footerDetails underline">kontakt@flinn.com</div>
      </div>
      <div>
       <div className="footerSmHeader">{t("us")}</div>
       <a
        href="https://help.hiflinn.com/en/articles/7257726-our-team"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("team")}
       </a>
       <a
        href="https://help.hiflinn.com/en/articles/7257725-why-flinn"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("why")}
       </a>
       <a
        href="https://help.hiflinn.com/en/articles/7257722-press"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("press")}
       </a>
       <a
        href="https://www.jobs.hiflinn.com/"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("career")}
       </a>
      </div>
      <div>
       <div className="footerSmHeader">{t("services")}</div>
       <a
        href="https://help.hiflinn.com/en/articles/7257715-civil-law-issues"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("civil")}
       </a>
       <a
        href="https://help.hiflinn.com/en/articles/7257718-criminal-law-issues"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("criminal")}
       </a>
       <a
        href="https://help.hiflinn.com/en/articles/7257721-constitutional-law-issues"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("law")}
       </a>
      </div>
      <div>
       <div className="footerSmHeader">{t("help")}</div>
       <a
        href="https://help.hiflinn.com/en"
        target="_blank"
        className="footerLink"
        rel="noreferrer"
       >
        {t("helpCenter")}
       </a>
       <a
        href="https://help.hiflinn.com/en/articles/7257713-all-beginnings-are-difficult"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
       >
        {t("difficult")}
       </a>
       <a
        href="https://help.hiflinn.com/en/articles/7257710-for-law-students"
        target="_blank"
        rel="noreferrer"
        className="footerLink"
       >
        {t("students")}
       </a>
      </div>
     </div>
     <div className="hide-pc cc">FLINN UG (Berlin Haftungsbeschränkt)</div>
     <div className="footerText">{t("note")}</div>
    </footer>
   </div>
  </div>
 );
}
