import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown } from "../assets/SVG";

const Accordion = ({ title, content, isActive, setIsActive, i }) => {
 const { t } = useTranslation();

 return (
  <div
   className="accordion-item"
   style={{
    borderWidth: isActive == i ? 0 : 1,
   }}
  >
   <div className="accordion-title flex sb" onClick={() => setIsActive(i)}>
    <div>{t(title)}</div>
    <div
     style={{
      transform: isActive == i ? "rotate(180deg)" : "rotate(0deg)",
     }}
    >
     <ArrowDown />
    </div>
   </div>
   <div
    className="accordion-content"
    style={{
     maxHeight: isActive == i ? "150px" : 0,
    }}
   >
    <div className="content-container">{t(content)}</div>
   </div>
  </div>
 );
};

export default Accordion;
