import React from "react";
import { useTranslation } from "react-i18next";

export default function CompareItem({
 HeaderItem,
 fee,
 speed,
 lang,
 res,
 issue,
 className,
}) {
 const { t } = useTranslation();
 return (
  <div className={className}>
   <div className="headerItem">{HeaderItem}</div>
   <div>
    <Item label={t("fee")} value={fee} />
    <Item label={t("speed")} value={speed} />
    <Item label={t("simple")} value={lang} />
    <Item label={t("download")} value={res} />
    <Item label={t("issues")} value={issue} />
   </div>
  </div>
 );
}

const Item = ({ label, value }) => (
 <div className="flex sb item">
  <div>{label}</div>
  <div>{value}</div>
 </div>
);
