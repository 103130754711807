import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Arrow, Verified } from "../assets/SVG";
import { simpleServiceData } from "../utils/content";
import Accordion from "./Accordion";

export default function Service() {
 const { t } = useTranslation();
 const [active, setActive] = useState(null);

 return (
  <div className="twoGrid hero service">
   <div className="leftSection">
    <div
     className="heroText hide-mobile"
     style={{
      maxWidth: "19ch",
     }}
    >
     {t("service")}
    </div>
    <div
     className="heroText hide-pc"
     style={{
      maxWidth: "19ch",
     }}
    >
     {t("easyService")}
    </div>
    <div className="accordion">
     {simpleServiceData.map(({ title, content }, i) => {
      return (
       <Accordion
        title={title}
        isActive={active}
        setIsActive={setActive}
        content={content}
        i={i}
       />
      );
     })}
    </div>
    <div
     onClick={() => {
      window.open("https://flinn-dashboard-fe.pages.dev/", "_blank");
     }}
     className="btn startAnalysis"
    >
     {t("startAnalysis")}
     <Arrow />
    </div>

    <div className="flex al-ce moneyBack hide-mobile">
     <Verified />
     <div>{t("moneyBack")}</div>
    </div>
   </div>
   <div className="rightSection">
    <div className="rightSectionContent"></div>
   </div>
  </div>
 );
}
