import React from "react";
import { useTranslation } from "react-i18next";
import { Verified } from "../assets/SVG";
import StartBtn from "./StartBtn";

export default function Hero() {
 const { t } = useTranslation();
 return (
  <div className="twoGrid hero">
   <div className="hide-pc solve">{t("start")}</div>
   <div className="leftSection">
    <div className="heroText">{t("hero")}</div>
    <div className="subText">{t("subText")}</div>
    <div className="hide-mobile">
     <StartBtn large />
    </div>
    <div className="hide-pc">
     <div
      onClick={() => {
       window.open("https://flinn-dashboard-fe.pages.dev/", "_blank");
      }}
      className="btn solveBtn"
     >
      {t("solve")}
     </div>
    </div>
    <div className="flex al-ce moneyBack hide-mobile">
     <Verified />
     <div>{t("moneyBack")}</div>
    </div>
   </div>
   <div className="rightSection">
    <div className="rightSectionContent">
     <img
      className="lawPerson"
      alt="law-Person"
      src={require("../assets/HeroImg.png")}
     />
    </div>
   </div>
  </div>
 );
}
