export const premiumNetworkData = [
    {
        title: 'partners',
        content: `partnerValue`
    },
    {
        title: 'confidential',
        content: `confidentialValue`
    },
    {
        title: 'goodFriend',
        content: `goodFriendValue`
    }
];


export const simpleServiceData = [
    {
        title: 'dataBanks',
        content: `dataBanksValue`
    },
    {
        title: 'unmanipulableSystem',
        content: `unmanipulableSystemValue`
    },
    {
        title: 'upToDate',
        content: `upToDateValue`
    }
];